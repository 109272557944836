@import url("https://fonts.googleapis.com/css2?family=Teko:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Oswald:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
}

/* @font-face {
  font-family: "Teko";
  src: url("./fonts/Teko-Regular.ttf");
} */
/* @font-face {
  font-family: "Teko";
  src: url("./fonts/Teko-Bold.ttf");
}
@font-face {
  font-family: "Teko";
  src: url("./fonts/Teko-Light.ttf");
}
@font-face {
  font-family: "Teko";
  src: url("./fonts/Teko-Regular.ttf");
}
@font-face {
  font-family: "Teko";
  src: url("./fonts/Teko-SemiBold.ttf");
} */

h2 {
  font-size: 4.5rem;
}
h3 {
  font-size: 2.4rem;
}

p {
  font-size: 1.6rem;
  line-height: 1.618em;
  letter-spacing: 1px;
}

a {
  color: white;
  text-decoration: none;
}

div {
  -ms-overflow-style: none;
}

div::-webkit-scrollbar {
  width: 0 !important;
}

.app {
  background-color: var(--main-background-color);

  color: var(--main-text-color);
  min-height: 100vh;
  font-family: "Teko", sans-serif;
  position: relative;
  z-index: 0;
}

.app-container {
  min-height: 100vh;
  z-index: 0;
  padding: 0 3%;
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 8% 1fr 8%;
  /* grid-template-columns: 1fr 70% 1fr; */
  max-width: 1700px;
  margin: 0 auto;
  overflow-x: hidden;
}

.courses-container {
  grid-column: 1 / 4;

  display: grid;
  grid-template-columns: 8% 1fr 8%;
  background-color: var(--secondary-background-color);
  margin-bottom: 5em;

  position: relative;
  box-shadow: 0px 10px 14px 0px #212529;
  padding-bottom: 2em;
}

.courses-wrapper {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1em;
}
.courses-heading {
  font-size: 4.5rem;
  margin-bottom: 0.5em;
  position: relative;

  transition: all 0.3s;
}

.courses-heading::before {
  content: "";
  position: absolute;
  max-width: 60%;
  height: 5px;
  background-color: rgb(255, 255, 255);

  transform: translateY(10px);
  transition: all 0.3s;
  display: none;
}
.courses-text-wrapper {
  max-width: 75%;
}
.courses-text-wrapper {
  max-width: 75%;
}

.courses-text {
  /* font-size: 1.6rem; */
  margin-bottom: 3em;
  max-width: 50%;
}

.sign-button {
  width: fit-content;
  font-size: 2em;
  background-color: var(--main-background-color);
  text-align: center;
  padding: 0.2em 0.7em;
  cursor: pointer;
}

.sign-button:hover {
  background-color: hsl(218, 23%, 25%);
}

@media only screen and (max-width: 494px) {
  .sign-button {
    font-size: 1.4rem;
  }
}

.courses-img {
  position: absolute;
  width: 35%;
  right: 5%;
  bottom: 7%;
  transition: all 0.3s;
}

@media only screen and (max-width: 1236px) {
  .courses-img {
    margin-bottom: 10%;
  }
}

@media only screen and (max-width: 960px) {
  .courses-heading::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 5px;
    background-color: rgb(255, 255, 255);

    transform: translateY(200px);
  }

  .courses-img {
    display: none;
  }

  .courses-text-wrapper {
    width: 100%;
  }

  .courses-text {
    max-width: 100%;
  }
}
@media only screen and (max-width: 570px) {
  .courses-heading {
    font-size: 2.7rem;
  }
  .courses-heading::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 5px;
    background-color: rgb(255, 255, 255);

    transform: translateY(120px);
  }

  .courses-text {
    margin-top: 1em;
  }
}

@media only screen and (max-width: 494px) {
  .courses-text {
    font-size: 1.4rem;
  }
}

.home-container {
  grid-column: 2 / 3;
  margin-bottom: 2em;
}

@media only screen and (max-width: 655px) {
  .home-container {
    grid-column: 1/5;
  }
}

.boxes {
  display: flex;
  flex-direction: column;
  gap: 5em;
}

.box-picture-on-left {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 14px 0px #212529;
}

.box-picture-on-right {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 14px 0px #212529;
  border-radius: 10px;
}
.image-left {
  grid-column: 1 / 2;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.image-right {
  grid-column: 2 / 3;
  max-width: 100%;
}

.box-title {
  font-size: 4.5rem;
  margin-bottom: 0.2em;
  position: relative;
  width: fit-content;

  transition: all 0.3s;

  line-height: 100%;
  margin-top: 0.3em;
}

.box-title::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 5px;
  background-color: rgb(255, 255, 255);
  transform: translateY(90px);
  transition: all 0.3s;
  display: none;
}

.about-right {
  grid-column: 2 / 3;
  height: auto;
  background-color: var(--secondary-background-color);
  display: flex;
  justify-content: flex-end;
  padding: 0 2em;
  padding-bottom: 2em;
}

.about-left {
  grid-column: 1 /2;
  height: auto;
  background-color: var(--secondary-background-color);
  display: flex;
  justify-content: flex-start;
  padding: 0 2em;
  padding-bottom: 2em;
}

.about-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  max-width: 100%;
}

.box-title-after {
  font-size: 4.5rem;
  margin-bottom: 0.2em;
  position: relative;
  display: inline-block;
  align-self: flex-end;
}

.about-right > .about-wrapper > .box-title-after {
  text-align: end;
  position: relative;
}

.about-right > .about-wrapper > .box-title-after::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 5px;

  transform: translateY(90px);
  right: 0%;
}

.about-text {
  font-size: 1.6rem;
  line-height: 1.618em;
  height: auto;
  transition: all 0.3s;
  margin-bottom: 1.5em;
}

.span-fitness {
  margin-bottom: 0.5em;
  font-weight: 700;
  display: block;
}

.fitness-sign-button {
  width: 100%;
  font-size: 2em;
  background-color: var(--main-background-color);
  text-align: center;
  padding: 0.2em 0.7em;
  cursor: pointer;
}

.fitness-sign-button:hover {
  background-color: hsl(218, 23%, 25%);
}

/* BOXES MEDIA QUERIES */

@media only screen and (max-width: 350px) {
  .box-title {
    font-size: 2.5rem;
  }

  .box-title::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 5px;
    background-color: rgb(255, 255, 255);
    transform: translateY(60px);
  }
}

@media only screen and (max-width: 494px) {
  .boxes {
    gap: 3em;
  }

  .about-text {
    font-size: 1.4rem;
  }

  .box-title {
    font-size: 2.6rem;
    margin-bottom: 0.6em;
  }

  .box-title::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 5px;
    background-color: rgb(255, 255, 255);

    transform: translateY(55px);
  }
  .fitness-sign-button {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 1160px) {
  .box-picture-on-left {
    flex-direction: row;
  }

  .box-picture-on-right {
    flex-direction: row-reverse;
  }

  .image-left {
    width: 60%;
    height: auto;
    object-fit: cover;
  }
  .image-right {
    width: 60%;
    height: auto;
    object-fit: cover;
  }

  .about-left {
    width: 40%;
  }
  .about-right {
    width: 40%;
  }
}

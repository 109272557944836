.nav-container {
  grid-column: 1 / 4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* height: 85px; */
  height: 75px;

  position: fixed;

  z-index: 6;
  top: 0;
  right: 0;
  width: 100%;
  background-color: var(--main-background-color);

  transition: all 0.2s ease-out;

  transform: translateY(0);
  opacity: 1;
}

.add-shadow {
  box-shadow: 0px 2px 14px 0px #212529;
}

.nav-container-hidden {
  transform: translateY(-80%);
  opacity: 0;
}

.navbar-logo-closed {
  width: 50px;
  transform: translateX(-150%);

  transition: all 0.2s;
  opacity: 0;
}

.navbar-logo-opened {
  transform: translateX(0);
  opacity: 1;
}

.mobile-navigation {
  background-color: var(--secondary-background-color);
  width: 60%;
  position: absolute;
  top: 0;
  height: 100vh;
  transition: all 0.2s ease-in-out;
}

.mobile-navigation-closed {
  transform: translateX(100%);
}

.mobile-menu-close-icon {
  scale: 1.5;
  color: rgb(223, 223, 223);
  z-index: 5;
}

.mobile-menu-icon {
  scale: 1.5;
  z-index: 5;
}

.mobile-navigation-ul {
  list-style: none;
  padding: 0;
  margin-top: 4em;
  font-size: 1.6rem;
}

.mobile-nav-item {
  padding-left: 15%;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.mobile-nav-item:hover {
  background-color: var(--main-background-color);
}

.touch-to-close {
  height: 100vh;
  width: 40vw;
  left: -40vw;
  position: absolute;
  transition: all 0.3s;
}

.touch-to-close-closed {
  transform: translateX(100%);
}

.navigation {
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navigation-ul {
  font-size: 1.6rem;
  display: flex;
  align-content: center;
  gap: 1.618em;
  list-style: none;
  padding: 0;
}

.nav-item {
  transition: 0.3s all;
  cursor: pointer;
}

.nav-item:hover {
  transition: 0.3s all;
  scale: 1.2;
  color: #1a6ee8;
}

/*  */

/* body {
  margin: 0;
  background-color: var(--main-background-color);
  color: var(--main-text-color);
  min-height: 100vh;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Teko", sans-serif;
  position: relative;
} */

.fitness-nav {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.812);
  position: fixed;
  width: 100%;
}

.fitness-nav > a {
  display: inline-block;
  font-size: 30px;
}

.fitness-nav > a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.fitness-container {
  grid-column: 1/4;
  padding-bottom: 6em;
}

.fitness-header {
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(
      0deg,
      rgba(34, 34, 34, 0.7),
      rgba(34, 34, 34, 0.7)
    ),
    url("../../public/images/nonstop-fitness/panorama_2-min.jpg");

  background-size: cover;
  background-attachment: fixed;
  -webkit-background-size: cover;
  display: grid;
  place-content: center;
}

.fitness-heading {
  font-size: 128px;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: column;
  line-height: 85%;
}

.fitness-heading > span {
  font-size: 68px;
  font-weight: 400;
}

@media only screen and (max-width: 460px) {
  .fitness-heading {
    font-size: 64px;
  }
  .fitness-heading > span {
    font-size: 34px;
  }

  .fitness-header {
    background-position-x: 30%;
  }
}

.fitness-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4em;
  margin: 5em 0;
  padding: 0 4%;
}
.fitness-about > p {
  text-align: center;
  max-width: 100ch;
}

.coach-image {
  width: 30%;
  object-fit: cover;
  object-fit: contain;
  align-self: start;
}

.fitness-coach-card {
  background-color: var(--secondary-background-color);
  padding: 4em 4%;

  display: flex;
  justify-content: center;
}

.coach-about {
  margin-left: 5em;
}

.coach-about > h2,
h3 {
  font-weight: 400;
}

.coach-about > p {
  max-width: 80ch;
}

@media only screen and (max-width: 1285px) {
  .fitness-coach-card {
    flex-direction: column-reverse;
    align-items: center;
  }

  .coach-image {
    margin-top: 3em;
    min-width: 300px;
    align-self: center;
  }

  .coach-about {
    max-width: 100%;

    margin-left: 0;
  }
}

@media only screen and (max-width: 774px) {
  .coach-about > h2 {
    font-size: 50px;
  }
  .coach-about > h3 {
    font-size: 35px;
    margin-bottom: 0.5em;
  }
}

.fitness-pricing > h2,
h3 {
  font-weight: 400;
}
.fitness-pricing > h2 {
  text-align: center;
  margin-bottom: 1em;
}
.fitness-pricing {
  padding-top: 4em;
  padding-bottom: 8em;
}

.price-tags-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
}
.price-tag {
  padding: 2em;
  border: 1px solid white;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
}

.price-tag > h3,
p {
  font-size: 36px;
  font-weight: 400;
}

.fitness-gallery-container {
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(autofit, 1fr);
  grid-auto-flow: dense;

  grid-gap: 1rem;
}

.fitness-gallery-image {
  width: 100%;

  object-fit: cover;
}

.two-rows {
  grid-row: span 1;
}

.two-columns {
  grid-column: span 2;
}

.fitness-gallery > h2 {
  font-weight: 400;
  text-align: center;
  margin-bottom: 1em;
}

.fitness-gallery {
  padding-top: 2em;

  max-width: 65%;
  margin: 0 auto;
}

@media only screen and (max-width: 1300px) {
  .fitness-gallery {
    max-width: 90%;
  }
}

@media only screen and (max-width: 550px) {
  .fitness-gallery > h2 {
    font-size: 50px;
  }
  .fitness-gallery-container {
    display: flex;
    flex-direction: column;
  }
}

p {
  font-size: 1.3rem;
  line-height: 1.618em;
  letter-spacing: 1px;
}

@media only screen and (max-width: 990px) {
  p {
    font-size: 18px;
  }
}

.price-tag > p {
  font-size: 36px;
  font-weight: 400;
}

.footer-container {
  background-color: var(--secondary-background-color);
  width: 100%;
  min-height: 400px;
  padding: 0 8%;
  padding-bottom: 2em;
  max-width: 1700px;
  margin: 0 auto;
  padding-top: 3em;
}

p {
  font-size: 1.6rem;
  line-height: 1.618em;
}

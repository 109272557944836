.questions-container {
  grid-column: 1/4;
  padding: 0 8%;
  min-height: 500px;
  padding-bottom: 5em;
  background-color: var(--secondary-background-color);
}

.questions-heading {
  font-size: 4.5rem;
  transition: all 0.3s;
  margin-bottom: 0.3em;
  margin-top: 0.5em;
}

.question-tabs {
  /* border: 1px solid white; */
  display: flex;
  flex-direction: column;
  gap: 4em;
  height: auto;
}

.question-wrapper {
  height: auto;
}

.question {
  background-color: var(--main-background-color);
  font-size: 2.5rem;
  text-align: center;
  padding: 0.5em 0.5em;
}

.answer {
  background-color: #303b4d;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow-y: scroll;
  font-size: 1.6rem;
  transition: all 0.8s;

  /* border: 1px solid white; */

  scrollbar-width: none;

  height: auto;
  padding: 1em;
}

.answer > p {
  text-align: center;
  /* border: 1px solid white; */
  /* height: 170px; */
  padding: 0 1em;
}

.answer-closed {
  height: 0px;
  transition: all 0.8s ease-in-out;
}

@media only screen and (max-width: 883px) {
  .questions-heading {
    font-size: 3.5rem;
  }
  .answer {
    /* height: 200px; */
  }

  .answer-closed {
    height: 0px;
    transition: all 0.3s ease-in-out;
  }
}
@media only screen and (max-width: 651px) {
  .answer-closed {
    height: 0px;
    transition: all 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 486px) {
  .answer > p {
    padding: 0;
  }

  .answer-closed {
    height: 0px;
    transition: all 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 463px) {
  .question {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 390px) {
  .answer-closed {
    height: 0px;
    transition: all 0.3s ease-in-out;
  }
}

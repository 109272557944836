.adcc-container {
  grid-column: 2 / 3;
  height: auto;
  background-color: var(--secondary-background-color);
  box-shadow: 0px 10px 14px 0px #212529;

  margin-bottom: 4em;

  display: grid;
  grid-template-columns: 5% 1fr 1fr 5%;
}

@media only screen and (max-width: 655px) {
  .adcc-container {
    grid-column: 1/5;
  }
}

.about-adcc {
  grid-column: 1 / 5;
  display: grid;
  grid-template-columns: 5% 1fr 1fr 5%;
  row-gap: 3em;
  margin-top: 1em;
  margin-bottom: 5em;
}

.logo-adcc {
  width: 300px;
  margin-bottom: 1em;
}

.adcc-heading {
  font-size: 4.5rem;
  grid-column: 3 / 4;
  justify-self: end;
}

.adcc-headings-wrapper {
  grid-column: 2/4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adcc-text {
  grid-column: 2 / 4;
  font-size: 1.6rem;
}

.adcc-photos-container {
  grid-column: 2/4;

  margin-bottom: 5em;
  display: grid;
  grid-template-columns: 55% 1fr;
  column-gap: 3%;

  width: 100%;
}

.adcc-photos-column {
  display: flex;
  gap: 2em;
  flex-direction: column;
  width: 100%;
}
.adcc-photos-column-1 {
  grid-template-areas: 1 / 3;
}
.adcc-photos-column-2 {
  grid-template-areas: 3 / 5;
}

.adcc-image-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 14px 0px var(--main-background-color);
  border-radius: 5px;
}

.grid-img {
  width: 100%;
  height: auto;

  transition: all 0.3s ease-in;

  border-radius: 5px;
}

.grid-img:hover {
  scale: 1.05;
}

.grid-img-1 {
  grid-column: span 3;
}

.grid-img-2 {
  grid-column: span 4;
}
.grid-img-4 {
  grid-column: span 2;
}

@media only screen and (max-width: 900px) {
  .adcc-photos-container {
    max-height: 1200px;

    grid-column: 1/5;
    margin-bottom: 0;
    overflow-y: scroll;
    grid-template-columns: 1fr;
    padding: 1.5em 1em;
    grid-gap: 3%;
  }

  .adcc-photos-column {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .adcc-headings-wrapper {
    flex-direction: column;
  }
}

@media only screen and (max-width: 494px) {
  .adcc-text {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 400px) {
  .adcc-heading {
    font-size: 2.7rem;
  }
}

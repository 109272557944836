code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  scroll-behavior: smooth;
  scroll-margin-bottom: 80px;
}

#about,
#pricing,
#adcc,
#gallery,
#contact {
  scroll-margin: 5em;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
body {
  margin: 0;
  background-color: var(--main-background-color);
  color: var(--main-text-color);
  min-height: 100vh;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Teko", sans-serif;
  position: relative;
}

:root {
  --main-background-color: #293241;
  --secondary-background-color: #3d5a80;
  --main-text-color: #fff;
}

.training-schedule-container {
  grid-column: 1 / 4;
  min-height: 400px;
  margin-bottom: 4em;
}

.training-schedule-container > h2 {
  margin: 0.7em 0;
  margin-bottom: 0.3em;
  text-align: center;
  font-size: 4.5rem;
  transition: all 0.3s;
}

.training-schedule-grid {
  grid-column: 2 / 3;
  background-color: var(--main-background-color);
  max-height: 800px;

  padding: 0 8%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  transition: all 0.3s;
  grid-gap: 3%;
  overflow-y: scroll;
  scrollbar-width: none;
}

@media only screen and (min-width: 1370px) {
  .training-schedule-grid {
    padding: 0 10%;
  }
}

.schedule-column {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
  color: var(--main-background-color);
  font-size: 1.4rem;
  grid-column: span 1;
  transition: all 0.3s;
  margin-top: 1em;
}

.schedule-column:hover {
  scale: 1.05;
}

.current-day {
  scale: 1.05;
}

.today-tag {
  position: absolute;

  top: -10px;
  right: -47px;

  padding: 0 2em;
  padding-top: 0.8em;
  background-color: red;
  color: white;

  transform: rotate(45deg);
  font-size: 1.4rem;

  transition: all 0.3s ease-in;
  z-index: 4;

  opacity: 1;
}

.today-tag-closed {
  transition: all 0.3s ease-out;

  top: -40px;
  right: -77px;
  opacity: 0;
}

.day {
  overflow: hidden;
  background-color: white;
  padding: 0.5em;
  text-align: center;

  position: relative;
}

.day > p {
  text-align: center;
}

.class-div {
  text-align: center;
  padding: 0.5em;
  background-color: var(--secondary-background-color);
}

.comp-class {
  background-color: #1c7ed6;
  color: white;
}

.grappling-class {
  background-color: white;
}
.mma-class {
  background-color: #c5f6fa;
}

.wrestling-class {
  background-color: #868e96;
  color: white;
}

.sparring-class {
  background-color: #ff582a;
  color: white;
}

@media only screen and (max-width: 706px) {
}

@media only screen and (max-width: 558px) {
  .training-schedule-container > h2 {
    font-size: 3.5rem;
  }
}
@media only screen and (max-width: 437px) {
  .training-schedule-container > h2 {
    font-size: 3.5rem;
  }
}

.carousel-container {
  grid-column: 1 / 4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 8%;
  background-color: var(--secondary-background-color);
  box-shadow: 0px 10px 14px 0px #212529;
  margin-bottom: 7em;
}

.athletes-carousel {
  min-height: auto;
  display: flex;
  align-items: center;
  overflow-y: visible;

  overflow-x: scroll;

  padding: 1em 0;

  scrollbar-width: none;
}

.arrow-wrapper {
  position: absolute;
  z-index: 3;

  margin: auto 0;

  display: flex;
  align-items: center;

  padding: 5em 2em;
  text-align: center;
  cursor: pointer;
}

.carousel-btn {
  transition: all 0.3s;

  scale: 1.9;
  color: white;

  color: #99b7ea;
}

.carousel-btn:active {
  color: #f0f6ff;
}

.carousel-btn-hover {
  transition: all 0.3s ease-out;
  color: #f0f6ff;
}

.left {
  left: 0;
}
.right {
  right: 0;
}

@media only screen and (max-width: 1068px) {
  .arrow-wrapper {
    padding: 4em 1em;
  }
}

@media only screen and (max-width: 700px) {
  .arrow-wrapper {
    padding: 3em 1em;
  }
}
@media only screen and (max-width: 520px) {
  .arrow-wrapper {
    padding: 3em 1em;
  }

  .athletes-carousel {
    width: 100%;
  }
}
@media only screen and (max-width: 509px) {
  .left {
    left: -3%;
  }
  .right {
    right: -3%;
  }

  .carousel-btn {
    scale: 1.2;
  }

  .arrow-wrapper {
    padding: 2em 0.4em;
  }
}

.slider-item-spacing-wrapper {
  padding: 5% 2%;

  flex: 0 0 33.33%;
  scroll-snap-align: start;
}

.slider-item {
  color: white;
  position: relative;

  transition: all 0.3s;
}

.slider-item-intersect {
  scale: 1.06;
}

.slider-item:hover {
  scale: 1.05;
}

.slider-item > img {
  width: 100%;

  height: 500px;
  object-fit: cover;
  box-shadow: 0px 10px 13px 0px var(--main-background-color);
  border-radius: 5px;
}

.about-slider-item {
  border-radius: 5px;
  background-image: linear-gradient(to top, #212529, rgba(0, 0, 0, 0) 60%);
  text-align: center;
  color: white;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: end;
  transition: all 0.3s ease-in;
}

.slider-item-text {
  margin-bottom: 7%;
}

.slider-item-text > h2 {
  font-size: 2.5vw;
}

.slider-item-text > h3 {
  font-size: 1.5vw;
}

.slider-item-insta-icon {
  margin-top: 0.3em;
  color: white;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.slider-item-insta-icon:hover {
  scale: 1.2;
}

@media only screen and (max-width: 1230px) {
  .slider-item-spacing-wrapper {
    flex: 0 0 50%;
  }

  .athletes-carousel {
    max-width: 800px;
  }

  .slider-item > img {
    box-shadow: 0px 10px 6px 0px var(--main-background-color);
  }

  .slider-item-text > h2 {
    font-size: 4vw;
  }

  .slider-item-text > h3 {
    font-size: 2.6vw;
  }

  .slider-item-insta-icon {
    font-size: 4vw;
    margin-top: 0.3em;
    color: white;
    transition: all 0.3s;
    cursor: pointer;
  }

  .slider-item-insta-icon:hover {
    scale: 1.3;
  }
}

@media only screen and (max-width: 685px) {
  .slider-item-spacing-wrapper {
    flex: 0 0 100%;
  }

  .athletes-carousel {
    max-width: 400px;
  }

  .slider-item-text > h2 {
    font-size: 2rem;
  }

  .slider-item-text > h3 {
    font-size: 1.4rem;
  }
}

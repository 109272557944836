.home-header {
  grid-column: 1/5;
  width: 100%;

  display: flex;
  align-items: flex-end;
  height: 100vh;
  overflow: hidden;

  position: relative;
  margin-bottom: 5em;
  box-shadow: 0px 2px 14px 0px #212529;

  z-index: 0;
}

.overlay {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0) 80%);
  position: absolute;
}

.logo {
  z-index: 7;
  position: absolute;
  top: 1em;

  visibility: hidden;
}

.logo > img {
  width: 130px;
}

.header-content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  max-width: 1700px;
  margin: 0 auto;
  padding: 0 3%;
}

.main-sign-container {
  width: fit-content;
  position: relative;
  margin-bottom: 4em;

  z-index: 5;
  text-shadow: 0px 2px 14px #212529;
}

.main-sign-h1 {
  font-size: 11rem;
  font-size: 9rem;
  color: #4263eb;
  line-height: 0.75em;
  transition: all 0.3s;
  animation: slide-from-left 1000ms;
  margin-bottom: 1em;
  margin-left: 0.4em;
}

@keyframes slide-from-left {
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(0);
  }
}
@keyframes slide-from-right {
  from {
    transform: translateX(170%);
  }

  to {
    transform: translateX(0);
  }
}

.main-sign-span-first {
  animation: slide-from-left 1000ms;
}

.main-sign-span-second {
  display: inline-block;
  font-size: 8.5rem;
  font-size: 6.5rem;
  color: #ced4da;
  margin-left: 20%;
  margin-bottom: 0.1em;
  transition: all 0.3s;
  animation: slide-from-right 1000ms;
}

.main-sign-span-third {
  animation: slide-from-right 1000ms;
}

/* .home-main-image {
  position: absolute;
  z-index: 4;
  width: 700px;
  right: 3%;
  border: 1px solid white;
  margin-left: auto;
  animation: main-image 2000ms;
} */

.home-main-image {
  position: absolute;
  z-index: 4;

  width: 95vh;
  right: 3%;

  margin-left: auto;
  animation: main-image 2000ms;
}

@keyframes main-image {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

@media only screen and (max-width: 1492px) {
  .main-sign-container {
    margin-bottom: 0;
  }
  .main-sign-h1 {
    margin-bottom: 0.4em;
  }
}

/* MAIN HEADING MEDIA QUERIES */

@media only screen and (max-width: 858px) {
  .home-header {
    height: 820px;
  }

  .home-main-image {
    width: 94%;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to top,
      #000000 30%,
      rgba(0, 0, 0, 0) 80%
    );
    position: absolute;
  }

  .main-sign-container {
    margin-left: auto;
    margin-right: auto;
  }

  .main-sign-h1 {
    font-size: 6.8rem;
    margin-left: 0;
  }
  .main-sign-span-second {
    font-size: 5.3rem;
  }
}

@media only screen and (max-width: 676px) {
  .home-main-image {
    top: 20%;
  }
}

@media only screen and (max-width: 602px) {
  .main-sign-h1 {
    font-size: 5rem;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to top,
      #000000 50%,
      rgba(0, 0, 0, 0) 80%
    );
    position: absolute;
  }

  .main-sign-span-second {
    font-size: 4rem;
  }

  .main-sign-container {
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 18%;
  }
}

@media only screen and (max-width: 493px) {
  .main-sign-container {
    margin-bottom: 35%;
  }
}

@media only screen and (max-width: 425px) {
  .main-sign-container {
    margin-bottom: 60%;
  }

  .main-sign-h1 {
    font-size: 4rem;
  }

  .main-sign-span-second {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 390px) {
  .main-sign-container {
    margin-bottom: 70%;
  }
}

.footer-container {
  background-color: var(--main-background-color);
  width: 100%;
  min-height: 400px;
  padding: 0 8%;
  padding-bottom: 2em;
  max-width: 1700px;
  margin: 0 auto;
}

h2 {
  /* font-size: 4.5rem; */
}

p {
  /* font-size: 1.6rem;
  line-height: 1.618em; */
}

.footer-main-h2 {
  margin-bottom: 0.3em;
  margin-top: 0.5em;
  transition: all 0.3s;
}

.two-percent-paragraph {
  margin-bottom: 2em;
  max-width: 900px;
  transition: all 0.3s;
}

.oz-info {
  margin-bottom: 4em;
  transition: all 0.3s;
}

.oz-info > p {
  font-weight: 600;
}

.footer-img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.contact-info-wrapper {
  display: flex;
  justify-content: space-between;

  margin-top: 5em;
  margin-bottom: 6em;
  transition: all 0.3s;
}

.contact-info-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  transition: all 0.3s;
  letter-spacing: 0.1rem;
}

.contact-info-container > h3 {
  margin-bottom: 0.5em;
  transition: all 0.3s;
}

.adcc-moto-container {
  font-family: "Dancing Script", cursive;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: fit-content;
  transition: all 0.3s;
}

.adcc-moto-container > p {
  font-size: 3.5rem;
  text-align: center;
  transform: translateY(14px);
}

.adcc-moto-container > img {
  width: 190px;
}

.footer-icons-container {
  display: flex;
  justify-content: center;
  gap: 2em;
}

.footer-icon {
  cursor: pointer;
}
.footer-icon:hover {
  scale: 1.1;
}

small {
  display: block;
  text-align: center;
  /* border: 1px solid white; */
  margin-top: 2em;
  font-size: 1.1rem;
  line-height: 1.418em;
  letter-spacing: 0.8px;
}

@media only screen and (max-width: 1100px) {
  .contact-info-wrapper {
    flex-direction: column;
    gap: 4em;
  }
  .adcc-moto-container {
    align-self: center;
  }
}

@media only screen and (max-width: 837px) {
  .footer-main-h2 {
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 527px) {
  .oz-info > h3 {
    font-size: 2rem;
    margin-bottom: 0.5em;
  }

  .oz-info > p {
    font-weight: 500;
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 465px) {
  .footer-main-h2 {
    font-size: 2.7rem;
  }

  .two-percent-paragraph {
    font-size: 1.4rem;
  }
  .contact-info-container > p {
    font-size: 1.4rem;
  }

  .adcc-moto-container {
    align-self: center;
  }
  .adcc-moto-container > p {
    font-size: 2.7rem;
  }
}

.pricing-container {
  grid-column: 2/3;
  margin-bottom: 7em;
}

.pricing-container > h3 {
  margin-bottom: 0.8em;
}

.pricing-text {
  display: flex;
  flex-direction: column;
  gap: 0.6em;
}
.pricing-span {
  font-weight: bold;
  margin-right: 0.8em;
}

@media only screen and (max-width: 500px) {
  .pricing-text {
    font-size: 1.4rem;
  }
}

.about-sga-container {
  margin-top: 5em;
  grid-column: 1 / -1;
  margin-bottom: 5em;

  position: relative;
}

.about-sga-upper {
  background-color: var(--secondary-background-color);
  padding-bottom: 4em;
  display: grid;
  grid-template-columns: 8% 1fr 8%;

  z-index: 1;
}

.about-sga-text-container {
  grid-column: 2 /3;
  margin-top: 1em;
}

.about-sga-text-container > h2 {
  font-size: 4.5rem;

  transition: all 0.3s;
}

.about-sga-text {
  font-size: 1.6rem;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* .about-sga-lower {
  background-color: var(--main-background-color);
  min-height: 560px;
  display: grid;
  grid-template-columns: 8% 1fr 8%;
  row-gap: 2em;
  padding-top: 2em;
} */

/* .competition-class {
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1em;
} */

/* .competition-class > h2 {
  font-size: 4.5rem;
  transition: all 0.3s;
} */

/* .competition-class > p {
  text-align: end;
  font-size: 1.6rem;
  width: 75%;
  transition: all 0.3s;
} */

/* .competition-team {
  font-size: 2.4rem;
  grid-column: 2/3;
  padding-bottom: 1.5em;
} */

/* .competition-team > p {
  font-size: 1.6rem;
  width: 75%;
  transition: all 0.3s;
} */

/* @media only screen and (max-width: 706px) {
  .competition-class > p {
    text-align: start;
    align-self: flex-start;
    width: 100%;
  }
  .competition-team > p {
    width: 100%;
  }

  .competition-class {
    justify-content: flex-start;
  }
} */

@media only screen and (max-width: 558px) {
  .about-sga-text-container > h2 {
    font-size: 3.5rem;
  }
  /* .competition-class > h2 {
    font-size: 3.5rem;
    margin-right: auto;
  } */

  .about-sga-text {
    max-width: 100%;
  }
}

@media only screen and (max-width: 494px) {
  .about-sga-text > p {
    font-size: 1.4rem;
  }

  /* .competition-text {
    font-size: 1.4rem;
  } */
}

@media only screen and (max-width: 437px) {
  .about-sga-text-container > h2 {
    font-size: 3.5rem;
  }
  /* .competition-class > h2 {
    font-size: 2.7rem;
  }
  .competition-team > h3 {
    font-size: 2.3rem;
  } */
}
